'use client';

import {
  ButtonIconSize,
  FsButtonIcon,
  FsFooter,
  FsFooterItem,
  FsFooterProps,
  FsLink,
} from '@printi/ds-offset-react-core';
import { useRouter } from 'next/navigation';
import { useHomeVersion } from '@mf/navigation/hooks/useHomeVersion';
import * as S from './Footer.styles';

export const Footer = ({
  copyrightText = `© ${new Date().getFullYear()} - Printi - CNPJ 13.555.994/0001-54`,
  showCta = false,
  ctaDescription = 'A sua gráfica, sempre.',
  ctaTitle = (
    <S.Text>Impressos, adesivos, brindes, embalagens e muito mais.</S.Text>
  ) as unknown as string,
  ctaButtonText = 'Ver todos',
  ctaImage,
}: FsFooterProps) => {
  const router = useRouter();
  const { isHomeV2 } = useHomeVersion();

  return (
    <S.FooterWrapper>
      <FsFooter
        hasLogo={!isHomeV2}
        copyrightText={copyrightText}
        showCta={showCta}
        ctaDescription={ctaDescription}
        ctaTitle={ctaTitle}
        ctaButtonText={ctaButtonText}
        ctaImage={ctaImage}
        onCtaButtonClick={() => router.push('/produtos')}
      >
        <FsFooterItem
          slot="items"
          as="a"
          // @ts-expect-error - href prop is not recognized
          href="https://www.printi.com.br/blog"
          target="_blank"
        >
          Blog
        </FsFooterItem>
        <FsFooterItem
          slot="items"
          as="a"
          // @ts-expect-error - href prop is not recognized
          href="/central-de-ajuda/"
          target="_self"
        >
          Central de Ajuda
        </FsFooterItem>
        <FsFooterItem
          slot="items"
          as="a"
          // @ts-expect-error - href prop is not recognized
          href="/institucional/"
          target="_self"
        >
          Institucional
        </FsFooterItem>
        <FsFooterItem
          slot="items"
          as="a"
          // @ts-expect-error - href prop is not recognized
          href="/carreiras/"
          target="_self"
        >
          Carreiras
        </FsFooterItem>
        <FsFooterItem
          slot="items"
          as="a"
          // @ts-expect-error - href prop is not recognized
          href="/printi-na-imprensa/"
          target="_self"
        >
          Imprensa
        </FsFooterItem>

        <FsButtonIcon
          icon="InstagramLine"
          slot="socialMediasIcons"
          ariaLabel="Instagram"
          size={ButtonIconSize.LG}
          // @ts-expect-error - as prop is not recognized
          as="a"
          href="https://www.instagram.com/printibr/"
          target="_blank"
          inverse
        />
        <FsButtonIcon
          icon="LinkedinLine"
          slot="socialMediasIcons"
          ariaLabel="LinkedIn"
          size={ButtonIconSize.LG}
          // @ts-expect-error - as prop is not recognized
          as="a"
          href="https://www.linkedin.com/company/printi/"
          target="_blank"
          inverse
        />
        <FsButtonIcon
          icon="GlassdoorLine"
          slot="socialMediasIcons"
          ariaLabel="Glassdoor"
          size={ButtonIconSize.LG}
          // @ts-expect-error - as prop is not recognized
          as="a"
          href="https://www.glassdoor.com.br/Vis%C3%A3o-geral/Trabalhar-na-Printi-EI_IE2482883.13,19.htm"
          target="_blank"
          inverse
        />
        {
          // TODO: Check links
        }
        {/* <FsLink
          slot="socialMediasLinks"
          inverse
          ariaLabel="Formulário LGPD"
          href="/formulario-lgpd"
          target="_self"
        >
          Formulário LGPD
        </FsLink> */}
        <FsLink
          slot="socialMediasLinks"
          inverse
          ariaLabel="Política de Cookies"
          href="/politicas-de-cookies/"
          target="_self"
        >
          Política de Cookies
        </FsLink>
        <FsLink
          slot="socialMediasLinks"
          inverse
          ariaLabel="Termos de Uso"
          href="/termos-de-uso/"
          target="_self"
        >
          Termos de Uso
        </FsLink>
        <FsLink
          slot="socialMediasLinks"
          inverse
          ariaLabel="Política de Privacidade"
          href="/politicas-de-privacidade/"
          target="_self"
        >
          Política de Privacidade
        </FsLink>
        <FsLink
          slot="socialMediasLinks"
          inverse
          ariaLabel="Política de Privacidade"
          href="https://docs.google.com/document/d/1sltLrNRES2KUGsFvC3NOXnbTpcactE5M/edit"
          target="_blank"
        >
          Relatório de Transparência e Igualdade Salarial
        </FsLink>
      </FsFooter>
    </S.FooterWrapper>
  );
};

import { PopoverMenuItemType } from '@printi/ds-offset-react-core';

export const menuItems = (pathname: string) => [
  {
    label: 'Início',
    index: 0,
    as: 'a',
    href: '/',
    selected: pathname === '/',
  },
  {
    label: 'Produtos',
    index: 1,
    as: 'a',
    href: '/produtos/',
    selected: pathname === '/produtos/',
    subItems: [
      {
        label: 'Papelaria',
        as: 'span',
        subItems: [
          {
            label: 'Caneta plástica color',
            as: 'a',
            href: '/caneta-plastica-color/',
          },
          {
            label: 'Caneta plástica stick',
            as: 'a',
            href: '/caneta-plastica-stick/',
          },
          {
            label: 'Caneta metal classic',
            as: 'a',
            href: '/caneta-metal-classic/',
          },
          {
            label: 'Caneta plástica cristal',
            as: 'a',
            href: '/caneta-plastica-cristal/',
          },
          {
            label: 'Caneta plástica escolar',
            as: 'a',
            href: '/caneta-plastica-escolar/',
          },
          {
            label: 'Caneta plástica básica',
            as: 'a',
            href: '/caneta-plastica-basica/',
          },
          {
            label: 'Caneta ecológica personalizada',
            as: 'a',
            href: '/caneta-ecologica/',
          },
          {
            label: 'Caneta slim touch',
            as: 'a',
            href: '/caneta-slim-touch/',
          },
          {
            label: 'Tudo em papelaria',
            as: 'a',
            href: '/papelaria/',
          },
        ],
      },
      {
        label: 'Brindes',
        subItems: [
          {
            label: 'Ecobag',
            as: 'a',
            href: '/sacola-personalizada-ecobag/',
          },
          {
            label: 'Botton',
            as: 'a',
            href: '/botton-personalizado/',
          },
          {
            label: 'Squeeze metal moderno personalizavel',
            as: 'a',
            href: '/squeeze-metal-moderno/',
          },
          {
            label: 'Mochila para notebook 15,6"',
            as: 'a',
            href: '/mochila-para-notebook-15-6/',
          },
          {
            label: 'Embalagem para caneca',
            as: 'a',
            href: '/embalagem-para-caneca/',
          },
          {
            label: 'Camiseta unissex premium',
            as: 'a',
            href: '/camiseta-unissex-premium/',
          },
          {
            label: 'Squeeze metal premium',
            as: 'a',
            href: '/squeeze-metal-premium/',
          },
          {
            label: 'Squeeze metal deluxe',
            as: 'a',
            href: '/squeeze-metal-deluxe/',
          },
          {
            label: 'Squeeze plástico fumê personalizado',
            as: 'a',
            href: '/squeeze-plastico-fume/',
          },
          {
            label: 'Mochila saco',
            as: 'a',
            href: '/mochila-saco',
          },
          {
            label: 'Caneca mágica personalizada',
            as: 'a',
            href: '/caneca-magica/',
          },
          {
            label: 'Tudo em brindes',
            as: 'a',
            href: '/brindes/',
          },
        ],
      },
      {
        label: 'Adesivos',
        subItems: [
          {
            label: 'Adesivo redondo',
            as: 'a',
            href: '/adesivo-redondo/',
          },
          {
            label: 'Adesivo oval',
            as: 'a',
            href: '/adesivo-oval/',
          },
          {
            label: 'Adesivo de parede',
            as: 'a',
            href: '/adesivo-de-parede/',
          },
          {
            label: 'Adesivos com corte especial',
            as: 'a',
            href: '/adesivo-personalizado/',
          },
          {
            label: 'Tudo em adesivos',
            as: 'a',
            href: '/adesivos/',
          },
        ],
      },
      {
        label: 'Vestuário',
        subItems: [
          {
            label: 'Camiseta unissex premium',
            as: 'a',
            href: '/camiseta-unissex-premium/',
          },
          {
            label: 'Tag personalizada',
            as: 'a',
            href: '/tag/',
          },
          {
            label: 'Camiseta polo',
            as: 'a',
            href: '/camiseta-polo/',
          },
          {
            label: 'Camiseta unissex clássica',
            as: 'a',
            href: '/camiseta-unissex-classica/',
          },
          {
            label: 'Mochila para notebook 15,6"',
            as: 'a',
            href: '/mochila-para-notebook-15-6/',
          },
          {
            label: 'Mochila saco',
            as: 'a',
            href: '/mochila-saco/',
          },
          {
            label: 'Tudo em vestuário',
            as: 'a',
            href: '/vestuario/',
          },
        ],
      },
      {
        label: 'Embalagens',
        subItems: [
          {
            label: 'Embalagem delivery de comida',
            as: 'a',
            href: '/embalagem-delivery/',
          },
          {
            label: 'Embalagem para caneca',
            as: 'a',
            href: '/embalagem-para-caneca/',
          },
          {
            label: 'Embalagem para doces e salgados',
            as: 'a',
            href: '/embalagem-para-doces-e-salgados/',
          },
          {
            label: 'Embalagem para cerveja',
            as: 'a',
            href: '/embalagem-cerveja/',
          },
          {
            label: 'Embalagem almofada',
            as: 'a',
            href: '/embalagem-almofada/',
          },
          {
            label: 'Embalagem basculante',
            as: 'a',
            href: '/embalagem-basculante/',
          },
          {
            label: 'Embalagem para combos',
            as: 'a',
            href: '/embalagem-para-combos/',
          },
          {
            label: 'Embalagem hexagonal',
            as: 'a',
            href: '/embalagem-hexagonal/',
          },
          {
            label: 'Embalagem com alça',
            as: 'a',
            href: '/embalagem-com-alca/',
          },
          {
            label: 'Sacola com adesivo',
            as: 'a',
            href: '/sacola-com-adesivo/',
          },
          {
            label: 'Tudo em embalagens',
            as: 'a',
            href: '/embalagens/',
          },
        ],
      },
      {
        label: 'Todos os produtos',
        showSubItemsIcon: false,
        as: 'a',
        href: '/produtos',
      },
    ],
  },
  {
    label: 'Institucional',
    index: 2,
    as: 'a',
    href: '/institucional/',
    selected: pathname === '/institucional/',
  },
  {
    label: 'Corporativo',
    index: 3,
    as: 'a',
    href: '/corporativo/',
    selected: pathname === '/corporativo/',
  },
];

export const userMenuItems = [
  {
    label: 'Minha conta',
    as: 'a',
    href: '/minha-conta',
    type: PopoverMenuItemType.Text,
    icon: 'HomeLine',
  },
  {
    label: 'Suporte no Whatsapp',
    as: 'a',
    href: 'https://api.whatsapp.com/send?phone=551141183816',
    target: '_blank',
    type: PopoverMenuItemType.Text,
    icon: 'WhatsappLine',
  },
];

export const userMenuItemsMobile = [
  {
    label: 'Pedidos',
    as: 'a',
    href: '/minha-conta',
    type: PopoverMenuItemType.Text,
    icon: 'ArchiveLine',
  },
  {
    href: '/minha-conta/dados-do-perfil',
    label: 'Dados do perfil',
    as: 'a',
    type: PopoverMenuItemType.Text,
    icon: 'UserLine',
  },
  {
    href: '/minha-conta/notificacoes',
    label: 'Notificações',
    as: 'a',
    type: PopoverMenuItemType.Text,
    icon: 'NotificationLine',
  },
  // {
  //   href: '/minha-conta/cartoes-salvos',
  //   label: 'Meio de pagamento',
  //   as: 'a',
  //   type: PopoverMenuItemType.Text,
  //   icon: 'CreditCardLine',
  // },
  {
    href: '/minha-conta/enderecos',
    label: 'Endereço',
    as: 'a',
    type: PopoverMenuItemType.Text,
    icon: 'MapPinLine',
  },

  {
    href: '/minha-conta/indicacoes',
    label: 'Indicações',
    as: 'a',
    type: PopoverMenuItemType.Text,
    icon: 'GiftLine',
  },
  {
    href: '/minha-conta/printi-money',
    label: 'Printi Money',
    as: 'a',
    type: PopoverMenuItemType.Text,
    icon: 'WalletLine',
  },

  {
    label: 'Suporte no Whatsapp',
    as: 'a',
    href: 'https://api.whatsapp.com/send?phone=551141183816',
    target: '_blank',
    type: PopoverMenuItemType.Text,
    icon: 'WhatsappLine',
  },
];

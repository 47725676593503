import styled from 'styled-components';

export const Text = styled.p`
  max-width: 540px;
  display: block;
  margin: 0 auto;
  padding: 0;
`;

export const FooterWrapper = styled.div`
  position: relative;

  div {
    position: relative;

    #footer-main-img {
      width: auto;
      max-width: 100%;
      height: initial;
    }
  }
`;

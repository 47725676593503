import media from '@mf/common/utils/styles/media';
import { FsProfileButton } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1001;

  > :first-child {
    max-width: inherit;
    position: initial;
  }

  ${media.lessThan('mobile')} {
    > div > div:not(#drawer-container) {
      position: absolute;
      top: 70px;
    }
  }

  header {
    position: initial;
    max-width: inherit;
  }

  #drawer-content {
    #drawer-slot-id {
      > div:not(#navbar)::-webkit-scrollbar {
        background: transparent;
      }
    }
  }

  #drawer-container {
    height: initial;
  }

  #drawer-container[aria-hidden='true'] {
    pointer-events: none;
  }
`;

export const ProfileButton = styled(FsProfileButton)`
  pointer-events: none;
`;

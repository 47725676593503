'use client';

import {
  ButtonSize,
  DrawerPositions,
  DrawerSizes,
  FsAccordion,
  FsAccordionGroup,
  FsButton,
  FsHeading,
  FsParagraph,
  FsTopSection,
  HeadingSize,
  HeadingTag,
  ListContentType,
  ListContentVariant,
} from '@printi/ds-offset-react-core';
import { useEffect } from 'react';
import { useRouter } from 'next/navigation';
import { usePromotions } from '@mf/common/contexts/promotions';
import * as S from './DrawerCoupon.styles';
import { DrawerCouponProps } from './DrawerCoupon.types';

export function DrawerCoupon({
  code,
  title,
  description,
  faq,
  isOpen,
  onClose,
}: DrawerCouponProps) {
  const { setDrawerCoupon } = usePromotions();

  const router = useRouter();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const renderListContent = () => {
    const labels = [
      'Créditos válidos por 6 meses',
      'Múltiplas indicações permitidas',
      'Válido apenas na primeira compra',
      'Indicado ganha: 30% de desconto na 1ª compra (até R$ 75)',
      'Você recebe R$ 20 em Printi Money por indicação validada',
      'Envie seu código por WhatsApp, e-mail ou redes sociais',
      'Quanto mais indicações, mais créditos você pode acumular!',
    ];
    return (
      <S.ListContentContainer>
        {labels.map((label, index) => (
          <S.StyledListContent
            key={`list-content-${index}`}
            showTag={false}
            showLabelLeft={true}
            showContentRight={true}
            showHeading={false}
            type={ListContentType.Icon}
            variant={ListContentVariant.Outline}
            heading=""
            labelLeft={label}
            labelRight=""
            description=""
            icon="CheckLine"
            showButton={false}
          />
        ))}
      </S.ListContentContainer>
    );
  };

  const drawerContentOficial = (
    <S.Container>
      <S.Top>
        <S.Header>
          <FsHeading tag={HeadingTag.H3} size={HeadingSize.MD}>
            {title}
          </FsHeading>
          <FsParagraph>{description}</FsParagraph>
          {renderListContent()}
        </S.Header>
        {code && (
          <FsButton
            size={ButtonSize.LG}
            onClick={() => {
              setDrawerCoupon({
                code: '',
                title: '',
                description: '',
                faq,
                isOpen: false,
                onClose,
              });
              router.push(`/minha-conta/indicacoes`);
            }}
            style={{ width: 'fit-content' }}
          >
            Copiar código de indicação
          </FsButton>
        )}
      </S.Top>
      <S.Bottom>
        {faq?.length && (
          <>
            <FsTopSection
              description=""
              showDescription={false}
              heading="Dúvidas Frequentes"
              headingProps={{ tag: HeadingTag.H2 }}
            />
            <FsAccordionGroup id="accordion-group">
              {faq.map((item, index) => (
                <FsAccordion
                  key={`acordion-${index}`}
                  id={`acordion-${index + 1}`}
                  index={index}
                  label={item.question}
                >
                  <FsParagraph>{item.answer}</FsParagraph>
                </FsAccordion>
              ))}
            </FsAccordionGroup>
          </>
        )}
      </S.Bottom>
    </S.Container>
  );

  return (
    <S.Drawer
      className="drawer-base-config"
      heading="Indique e Ganhe"
      navBarHeadingProps={{
        tag: HeadingTag.H2,
      }}
      isOpen={isOpen}
      size={DrawerSizes.LG}
      position={DrawerPositions.Right}
      onClose={onClose}
      showNavBar
    >
      {drawerContentOficial}
    </S.Drawer>
  );
}

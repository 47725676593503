'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export function useHomeVersion() {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const [state, setState] = useState({
    isHomeV2: false,
    isHomePage: false,
  });

  useEffect(() => {
    const versionParam = searchParams?.get('version');

    setState({
      isHomeV2: versionParam === '2',
      isHomePage: pathname === '/',
    });
  }, [searchParams, pathname]);

  return state;
}

'use client';
import * as S from './Container.styles';

export const Container = ({ children }: { children: React.ReactNode }) => {
  return (
    <S.Container>
      <div className="fs-container">{children}</div>
    </S.Container>
  );
};

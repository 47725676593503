'use client';

import { usePromotions } from '@mf/common/contexts/promotions';
import { useLayout } from '@mf/common/components/Media/Layout';
import { FsTopBarItem } from '@printi/ds-offset-react-core';
import { TTopBarItem } from '@mf/promotions/repositories/aecomStrapiCms/topBar/types';
import { useTopBarHooks } from './TopBar.hooks';
import { StyledTopBar } from './TopBar.styles';

export interface TopBarProps {
  items: TTopBarItem[];
}

export function ClientTopBar({ items }: TopBarProps) {
  const { onClick } = useTopBarHooks();
  const { setDrawerCoupon } = usePromotions();
  const { isMobile } = useLayout();

  return (
    <StyledTopBar>
      {items &&
        items.map((item) => {
          return (
            <FsTopBarItem
              key={item?.id}
              description={
                isMobile
                  ? item?.attributes?.mobileText
                  : item?.attributes?.desktopText
              }
              buttonText={
                isMobile
                  ? item?.attributes?.mobileCta
                  : item?.attributes?.desktopCta
              }
              onClick={() => onClick({ item, setDrawerCoupon })}
            />
          );
        })}
    </StyledTopBar>
  );
}

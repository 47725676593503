import { FsModal } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Modal = styled(FsModal)`
  z-index: 4000;
  #modal {
    max-width: 600px;

    p {
      max-width: 400px;
      text-align: center;
    }
  }
  a:has(span) {
    background-color: var(--action-bg-primary-color) !important;
  }

  div[type='neutral'] {
    background-color: var(--elements-bg-color-02) !important;
    > div {
      color: var(--on-elements-icon-color) !important;
    }
  }
`;

'use client';

import { useLayout } from '@mf/common/components/Media/Layout';
import { Container } from '@mf/common/components/Container';
import { useEffect, useLayoutEffect, useState } from 'react';
import { usePromotions } from '@mf/common/contexts/promotions/promotions';
import { FsButton, ModalType } from '@printi/ds-offset-react-core';
import Image from 'next/image';
import { useHomeVersion } from '@mf/navigation/hooks/useHomeVersion';
import { Drawers } from '../Drawers';
import { Footer } from '../Footer';
import { Modal } from './Main.styles';

interface MainProps {
  children: React.ReactNode;
  hideFooter?: boolean;
  hideHeaderSearch?: boolean;
  noMaxWidth?: boolean;
}

export const Main = ({ children, hideFooter, noMaxWidth }: MainProps) => {
  const { showCta, setShowCta } = useLayout();
  const [showVersionModal, setShowVersionModal] = useState(false);
  const { drawerCoupon } = usePromotions();
  const hideDrawerCoupon =
    drawerCoupon.code === '' && drawerCoupon.title === '' ? true : false;

  const { isHomeV2 } = useHomeVersion();

  useLayoutEffect(() => {
    const lastTime = new Date(
      parseInt(window.localStorage.getItem('showVersionModal') ?? '0'),
    );
    const currentTime = new Date();
    let diffTime = (currentTime.getTime() - lastTime.getTime()) / 1000;
    diffTime /= 15;

    if (diffTime < 15) {
      setShowVersionModal(false);
    }
  }, []);

  useEffect(() => {
    // Only set showCta to false for HomeV2
    if (isHomeV2) {
      setShowCta(false);
    } else {
      setShowCta(true);
    }
  }, [isHomeV2, setShowCta]);

  useEffect(() => {
    if (window.localStorage.getItem('showVersionModal') !== undefined) {
      const lastTime = new Date(
        parseInt(window.localStorage.getItem('showVersionModal') ?? '0'),
      );
      const currentTime = new Date();
      let diffTime = (currentTime.getTime() - lastTime.getTime()) / 1000;
      diffTime /= 15;

      if (diffTime < 15) {
        setShowVersionModal(false);
        return;
      }
    }

    if (navigator.userAgentData) {
      navigator.userAgentData
        .getHighEntropyValues(['fullVersionList'])
        .then((ua) => {
          const chromeVersion = ua?.fullVersionList?.find(
            (item) => item.brand === 'Google Chrome',
          );
          if (
            chromeVersion &&
            (chromeVersion.version === '129.0.6668.60' ||
              chromeVersion.version === '129.0.6668.59')
          ) {
            setShowVersionModal(true);
          }
        });
    }
  }, []);

  const handleVersionModalClose = () => {
    window.localStorage.setItem('showVersionModal', `${new Date().getTime()}`);
    setShowVersionModal(false);
  };

  return (
    <>
      {showVersionModal && (
        <Modal
          fixedBarActionSlot={
            <FsButton
              // @ts-expect-error - as prop is not recognized
              as="a"
              target="_blank"
              href="https://www.google.com/intl/pt-BR/chrome/update/"
            >
              Saiba como
            </FsButton>
          }
          type={ModalType.Danger}
          heading="Atualize seu Chrome"
          description="Você precisa atualizar a versão do seu navegador para ter uma boa experiência."
          onClose={handleVersionModalClose}
          isOpen={showVersionModal}
          icon={'WarningLine'}
          className="modal"
        />
      )}
      {noMaxWidth ? <>{children}</> : <Container>{children}</Container>}
      {!hideFooter && (
        <Footer
          showCta={showCta}
          ctaImage={
            <Image
              src="https://d1br4h274rc9sc.cloudfront.net/content/footer_dc7f540926.png"
              alt="Printi - Footer"
              width={1210}
              height={795}
              id="footer-main-img"
            />
          }
        />
      )}
      {!hideDrawerCoupon && <Drawers />}
    </>
  );
};

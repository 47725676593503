'use client';

import { usePromotions } from '@mf/common/contexts/promotions';
import { DrawerCoupon } from '@mf/promotions/components/DrawerCoupon';

export function Drawers() {
  const { drawerCoupon } = usePromotions();

  return <DrawerCoupon {...drawerCoupon} />;
}

'use client';

import React, { MouseEvent, forwardRef } from 'react';
import * as S from './HomeSearch.styles';

interface HomeSearchProps {
  placeholder?: string;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  tabIndex?: number;
}

export const HomeSearch = forwardRef<HTMLDivElement, HomeSearchProps>(
  ({ placeholder = '', onClick, tabIndex }, ref) => {
    const [clicked, setClicked] = React.useState(false);

    const handleClick = (evt: MouseEvent<HTMLDivElement>) => {
      setClicked(true);
      onClick?.(evt);
    };

    const handleBlur = () => {
      setClicked(false);
    };

    return (
      <S.Container
        ref={ref}
        tabIndex={tabIndex}
        onClick={handleClick}
        onBlur={handleBlur}
        data-clicked={clicked}
      >
        <S.Input
          type="text"
          placeholder={placeholder}
          data-testid="home-search"
        />
        <S.InputIcons>
          <S.ButtonIcon
            aria-label="Botão de pesquisa"
            icon="SearchLine"
            data-testid="home-search-button-icon"
          />
        </S.InputIcons>
      </S.Container>
    );
  },
);

HomeSearch.displayName = 'HomeSearch';

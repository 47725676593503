'use client';

import React, { useCallback, useMemo } from 'react';
import {
  ButtonIconSize,
  FsButtonIcon,
  FsHeader,
  FsPopoverMenuItem,
  FsSubItemMenu,
} from '@printi/ds-offset-react-core';
import { usePathname, useRouter } from 'next/navigation';
import { signOut } from 'next-auth/react';
import { useLayout } from '@mf/common/components/Media/Layout';
import { ReferralBanner } from '@mf/common/components/index';
import { useCartStore } from '@mf/common/store/cart/cart.store';
import { useHomeVersion } from '@mf/navigation/hooks/useHomeVersion';
import { userMenuItems, userMenuItemsMobile } from '../constants';
import { processMenuData } from '../utils';
import * as S from './Header.styles';
import { ValidUserSession } from './ValidUserSession';

interface ClientHeaderProps {
  menuItems: any[]; // eslint-disable-line @typescript-eslint/no-explicit-any
  isAuthenticated: boolean;
  customerData: {
    first_name?: string;
  };
  showSearchButton?: boolean;
}

export const ClientHeader = ({
  menuItems,
  isAuthenticated,
  customerData,
}: ClientHeaderProps) => {
  const { push } = useRouter();
  const pathname = usePathname();
  const { isHomePage } = useHomeVersion();
  const { isMobile, setShowSearchPage } = useLayout();

  const menuItemsByPath = useMemo(() => {
    return processMenuData(menuItems, pathname);
  }, [menuItems, pathname]);

  const {
    clearCart,
    lastCartItemsTotal,
    setLastCartItems,
    setLastCartId,
    setOfflineCartId,
    setLastCartItemsTotal,
    setCustomerId,
  } = useCartStore();

  const clearCartData = useCallback(() => {
    setLastCartItems([]);
    setLastCartId(null);
    setOfflineCartId(null);
    setCustomerId(null);
    setLastCartItemsTotal(0);
  }, [
    setLastCartItems,
    setLastCartId,
    setOfflineCartId,
    setLastCartItemsTotal,
    setCustomerId,
  ]);

  const isCheckout = (pathname || '').includes('finalizar-compra');

  const onLoginHandler = () => {
    window.location.href = '/login?callbackUrl=' + pathname;
  };

  const onCartClickHandler = () => {
    push('/carrinho');
  };

  const onMenuClickHandler = () => {
    window.dataLayer.push({
      event: 'genericEvent',
      event_name: 'main_menu_interaction',
    });
  };

  const signOutButton = useMemo(() => {
    // eslint-disable-next-line react/display-name
    return () => (
      <FsPopoverMenuItem
        style={{ width: 'auto' }}
        label={'Sair'}
        icon={'LogoutLine'}
        onSelected={() => {
          signOut({
            redirect: false,
          });
          clearCart();
          setTimeout(() => {
            window.location.href = '/';
            clearCartData();
          }, 1500);
        }}
      />
    );
  }, [clearCart, clearCartData]);

  const renderDesktopMenu = useMemo(() => {
    return (
      <>
        {userMenuItems.map((props, index) => (
          <FsPopoverMenuItem {...props} key={`item-${index}`} />
        ))}
        {signOutButton()}
      </>
    );
  }, [signOutButton]);

  const renderMobileMenu = useMemo(() => {
    return (
      <>
        {userMenuItemsMobile.map((props, index) => (
          <FsPopoverMenuItem {...props} key={`item-${index}`} />
        ))}
        <ReferralBanner />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            borderTop:
              'var(--default-border-width) solid var(--elements-border-color)',
            marginTop: 'var(--size-1x)',
          }}
        >
          <S.ProfileButton
            label={customerData?.first_name}
            showButton={false}
          />
          {signOutButton()}
        </div>
      </>
    );
  }, [customerData, signOutButton]);

  return (
    <S.Container>
      <FsHeader
        hasLogoH1={isHomePage}
        drawerMenuTitle="Menu"
        drawerUserOptionsTitle="Minha conta"
        searchPlaceholder="Busque por produtos aqui..."
        onLogin={onLoginHandler}
        onCartClick={onCartClickHandler}
        showSearch
        headerSearchPlaceholder="Qual produto você está procurando?"
        logged={isAuthenticated}
        userName={customerData?.first_name}
        amountCartItems={lastCartItemsTotal}
        responsive={isMobile}
        showAction={isCheckout ? false : true}
        showNav={isCheckout ? false : true}
        ghost={isCheckout}
        onSearchClick={() => setShowSearchPage(true)}
        onMenuClick={onMenuClickHandler}
        // @ts-expect-error - as prop is not recognized
        menuItems={menuItemsByPath}
        navMenuFooterText={`© ${new Date().getFullYear()} - Printi - CNPJ 13.555.994/0001-54`}
        style={{
          backgroundColor: isCheckout ? 'var(--color-grey-0)' : 'inherit',
        }}
        socialMediaElements={
          <>
            <FsButtonIcon
              inverse
              icon="InstagramLine"
              size={ButtonIconSize.LG}
              // @ts-expect-error - as prop is not recognized
              as="a"
              href="https://www.instagram.com/printibr/"
              target="_blank"
            />
            <FsButtonIcon
              inverse
              icon="LinkedinLine"
              size={ButtonIconSize.LG}
              // @ts-expect-error - as prop is not recognized
              as="a"
              href="https://www.linkedin.com/company/printi/"
              target="_blank"
            />
            <FsButtonIcon
              inverse
              icon="GlassdoorLine"
              size={ButtonIconSize.LG}
              // @ts-expect-error - as prop is not recognized
              as="a"
              href="https://www.glassdoor.com.br/Vis%C3%A3o-geral/Trabalhar-na-Printi-EI_IE2482883.13,19.htm"
              target="_blank"
            />
          </>
        }
        userDropdownElements={
          <>{isMobile ? renderMobileMenu : renderDesktopMenu}</>
        }
        navBottomElements={
          <>
            <FsSubItemMenu
              showIcon={false}
              label="Blog"
              as="a"
              href="https://www.printi.com.br/blog"
              target="_blank"
            />
            <FsSubItemMenu
              showIcon={false}
              label="Central de Ajuda"
              as="a"
              href="/central-de-ajuda/"
              onClick={() => push('/central-de-ajuda/')}
            />
            <FsSubItemMenu
              showIcon={false}
              label="Carreiras"
              as="a"
              href="/carreiras/"
              onClick={() => push('/carreiras/')}
            />
            <FsSubItemMenu
              showIcon={false}
              label="Imprensa"
              as="a"
              href="/printi-na-imprensa/"
              onClick={() => push('/printi-na-imprensa')}
            />
            <FsSubItemMenu
              showIcon={false}
              as="a"
              href="https://api.whatsapp.com/send?phone=551141183816"
              label="Suporte por Whatsapp"
              target="_blank"
            />
          </>
        }
      />

      <ValidUserSession />
    </S.Container>
  );
};

import { media } from '@mf/common/utils/styles/index';
import { FsDrawer, FsListContent } from '@printi/ds-offset-react-core';
import styled from 'styled-components';

export const Drawer = styled(FsDrawer)``;

export const StyledListContent = styled(FsListContent)`
  border: none;
  padding: var(--spacing-1x) var(--spacing-1x) var(--spacing-1x) 0;

  :nth-child(1) {
    gap: var(--spacing-1x);
  }
`;

export const ListContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Top = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  border-radius: var(--border-radius-md);
  border: 1.6px;
  padding: var(--spacing-5x);
  gap: var(--spacing-6x);
  background-color: var(--color-grey-50);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-2x);
  }
`;

export const Bottom = styled.div`
  width: 100%;
  height: auto;
  max-width: -moz-available;
  max-width: -webkit-fill-available;
  max-width: fill-available;

  ${media.lessThan('mobile')} {
    padding: var(--spacing-2x);
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: var(--spacing-3x);
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-7x);

  ${media.lessThan('mobile')} {
    padding: var(--spacing-2x);
  }
`;

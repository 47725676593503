import styled from 'styled-components';
import { media } from '../../utils/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--size-6x);

  ${media.lessThan('mobile')} {
    margin-top: var(--size-5x);
  }
`;

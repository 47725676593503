/* eslint-disable @typescript-eslint/no-explicit-any */
import { menuItems } from '../constants';

export function processMenuData(menuData: any, pathname: string) {
  const baseMenu = menuItems(pathname);

  if (!menuData) {
    return baseMenu;
  }

  const dynamicData = menuData[0]?.attributes.menu_item.map((item: any) => ({
    label: item.label,
    as: item.showSubItemsIcon === false ? 'a' : 'span',
    showSubItemsIcon: item.showSubItemsIcon,
    href: item.href,
    subItems: item.menu_sub_item.map((subItem: any) => ({
      label: subItem.label,
      as: 'a',
      href: subItem.href,
    })),
  }));

  // Add dynamic data to the first item of the base menu
  if (baseMenu[1]) {
    baseMenu[1] = { ...baseMenu[1], subItems: dynamicData };
  }

  return baseMenu;
}

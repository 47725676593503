'use client';

import React from 'react';
import bffCustomerData from '@mf/auth/repositories/bff/customer-data';
import { CustomSession } from '@mf/common/config/nextAuth/types';
import { signOut, useSession } from 'next-auth/react';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect } from 'react';

export const ValidUserSession = () => {
  const { push } = useRouter();
  const { data: session } = useSession();
  const userSession = session as CustomSession;
  const pathname = usePathname();

  useEffect(() => {
    if (!userSession?.provider?.access_token) {
      return;
    }
    signOut();
    push('/');
  }, [userSession?.provider?.access_token, push]);

  const { useCustomerdata } = bffCustomerData({
    baseUrl: process.env.BFF_API_BASE_URL,
    token: userSession?.user?.token,
  });
  const { data: customerData } = useCustomerdata();

  useEffect(() => {
    const id = customerData?.id;
    const sessionId = userSession?.user?.id;

    if (!id || !sessionId) {
      return;
    }

    const cnpj = customerData?.cnpj;
    const cpf = customerData?.cpf;
    const birthdate = customerData?.birthdate;

    const hasCnpjOrCpf = !!cnpj || !!cpf;
    const redirectCpf = !birthdate && cpf;

    const isCpfComplete = (!hasCnpjOrCpf && !cpf) || redirectCpf;
    const isCnpjComplete = !hasCnpjOrCpf && !cnpj;

    if (isCpfComplete) {
      setTimeout(() => {
        push('/login/6?callbackUrl=' + pathname);
      }, 2000);
      return;
    }

    if (isCnpjComplete) {
      setTimeout(() => {
        push('/login/7?callbackUrl=' + pathname);
      }, 2000);
      return;
    }
  }, [customerData, push, pathname, userSession]);

  return <></>;
};

import styled from 'styled-components';
import * as mixins from '@printi/ds-offset-tokens/dist/sc/mixins';
import { FsButtonIconHighlight } from '@printi/ds-offset-react-core';

export const Container = styled.div`
  width: 100%;
  max-width: 668px;
  height: var(--size-6x);
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  gap: var(--spacing-1x);
  justify-content: space-between;
  box-sizing: border-box;
  padding: var(--spacing-1_5x) var(--spacing-2x);

  border-radius: var(--border-radius-circular);
  border: 1.6px solid var(--elements-border-color);

  @media screen and (min-width: 1440px) {
    max-width: 898px;
    height: var(--size-10x);
    padding: var(--spacing-2x) var(--spacing-3x);
  }
`;

export const Input = styled.input`
  ${mixins._labelSm1}

  width: inherit;
  height: inherit;
  outline: none;
  border: none;
  box-sizing: border-box;
  background: transparent;
  color: var(--text-placeholder-color);

  transition-timing-function: var(--curve-expressive-entrance);
  transition-property: opacity;
  transition-duration: 1000ms;

  &::placeholder {
    color: var(--text-placeholder-color);
  }

  @media screen and (min-width: 1440px) {
    ${mixins._labelMd1}
  }
`;

export const InputIcons = styled.div`
  display: flex;

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }
`;

export const ButtonIcon = styled(FsButtonIconHighlight)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--elements-bg-color-04);
  border: none;

  @media screen and (max-width: 1439px) {
    width: var(--size-4x);
    height: var(--size-4x);
  }
`;
